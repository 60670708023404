.l-thumb2{
  max-height: 120px;
}
.l-thumb2 .l-date2 {
  position: relative;
   bottom: 27px;
 /*  left: 0; */ 
   width: 150px;
  color:#fff ;
 /*  top: 94px; */
  background-color: rgba(0,0,0,.6);
}
.l-date2 {
  background-color: rgba(0,0,0,.6);
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  font-family: Arial,Helvetica,sans-serif;
  padding: 5px;
  text-align: center;
  font-style: italic;
}
.l-meta--hrz2 > a {
  color: #da3e4b;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 11px;
  line-height: 1;
font-weight: bold;
display: inline-block;
}
.ql-editor a {
  font-size: 20px !important;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-wrapper{
  min-height: 600px;
}
.l-ad-wrap {
  margin: 0 0 .5rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.lead-story .adsbtm {
  margin-top: 10px;
}
.main-story .con300x250 { max-width: 100%; }
.news-dtls {
  max-height: 123px;
  overflow: hidden;
  padding-top: 1px;
}
.main-story-img{
  object-fit: cover !important;
  width: 300px;
  height: 220px;
}
.video-list img{ max-width: 75px;}
.news-detail h3{
  font-size: 24px;
  line-height: 30px;
}
.news-dtls6 {
    height: 225px !important;
    padding-top: 1px;
}
  .top-story-img{
    object-fit: cover !important;
    width: 150px;
    height: 120px;
    }
    .lead-story-img{
      object-fit: cover !important;
      width: 110px;
      height: 80px;
    }
    .sty1-asso-img{
      object-fit: cover !important;
      height: 200px;
      margin-top: 8px;
    }
    .styl3-uk-img{
      object-fit: cover !important;
      width: 230px;
      height: 280px;
    }
    .styl3-uk-rig-img{
      object-fit: cover !important;
      width: 130px;
      height: 80px; 
    }
    .styl4-char-img{
      object-fit: cover !important;
      width: 230px;
      height: 265px; 
    }
    .styl4-mam-img{
      object-fit: cover !important;
      width: 150px;
      height: 130px;
    }
    .styl9-abhi-img{
      object-fit: cover !important;
      width: 130px;
      height: 75px; 
    }
    .styl10-slid-img{
      object-fit: cover !important;
      width: 200px;
      height: 115px;  
    }
    .recom-img{
      object-fit: cover !important;
      width: 110px;
      height: 75px;  
    }
    .news-dtls2 {
      height: 190px;
    
    }
    .most-read .news__item + .news__item {
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px dotted #414141;
  }


  .tab-card {
    border:1px solid #eee;
  }
  
  .tab-card-header {
    background:none;
  }
  /* Default mode */
  .tab-card-header > .nav-tabs {
    border: none;
    margin: 0px;
  }
  .tab-card-header > .nav-tabs > li {
    margin-right: 2px;
  }
  .tab-card-header > .nav-tabs > li > a {
    border: 0;
    border-bottom:2px solid transparent;
    margin-right: 0;
    color: #737373;
    padding: 2px 15px;
  }
  .header-ads__item img{ max-height: 90px !important;}
  .tab-card-header > .nav-tabs > li > a.show {
      border-bottom:2px solid #007bff;
      color: #007bff;
  }
  .tab-card-header > .nav-tabs > li > a:hover {
      color: #007bff;
  }
  
  .tab-card-header > .tab-content {
    padding-bottom: 0;
  }
.most-read button{

  padding: 5px 10px;
  margin-right: 5px;
}
.most-read .city{
  margin-top: 15px;
}
.ql-editor img {
  padding: 15px 15px 15px 0;
  max-width: 100%;
  float: left;
}
.ql-editor li[data-list="bullet"] {
  list-style-type: disc !important;
}

.con300x250{

  max-width: 300px;
  margin: auto;
}
.ZNE016 img, .ZNE009 img {width: 100%;}
.charity  .con300x250{


  height: 250px !important;
}
.news-dtls4 {
  overflow: hidden;
  height: 275px;
}
.charity .l-ad-wrap img{ width: auto !important; }
.matrimony .l-ad-wrap { text-align: center;}

.association .l-ad-wrap img{ max-height: 102px !important; }
.spitual img.sty1-asso-img{ margin-top: 0px !important; }
@media screen and (max-width: 800px) {
  .header-ads__item img { width: 100%;}
  .header-ads .ZNE002, .header-ads .ZNE003 {/* display: none;*/}
  .header__block {
    display: block !important;
    text-align: center;
  }
  .header__block div {
    margin: 5px 0;
   
  }
  .header-ads {
    display: block !important;
  }
  .sty1-asso-img {
    object-fit: cover !important;
    height: 230px;
    margin-top: 8px;
  }
  .styl3-uk-img {
      object-fit: cover !important;
      width: 100%;
      height: auto; 
  }
  .main-story-img{ width: 100%;}
  
  .adImg{ max-width:100%; }
  .sty1-asso-img {
      object-fit: cover !important;
      height: auto;
      margin-top: 8px;
      width: 100%;
  }
  .news-dtls2 {
      height: 190px !important;
  }
 
}

@media screen and (max-width: 1400px) {
  .mainnav__link {
    font-size: .8rem;
    
  } 
}